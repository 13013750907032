.react-datepicker__day_hover {
    background-color: #f0f0f0;
    color: #000;
}

.react-datepicker__month-text--selected,
.react-datepicker__day--in-range,
.react-datepicker__day--selected {
    background-color: #BE131A !important;
    color: #fff !important;
}

.react-datepicker__month-text--selected:hover,
.react-datepicker__day--selected:hover,
.react-datepicker__day--selected.react-datepicker__day_hover,
.react-datepicker__day--in-range.react-datepicker__day_hover {
    background-color: #b31017 !important;
    color: #fff !important;
}
